<template>
<div v-for="item in tuiguang" :key="item" >


 <div class="header"></div>
  <div class="wrap"  v-for="list in item.banner" :key="list">
    <div class="center" >
     <img :src="list.banner1" />
     <img :src="list.banner2" />
     <img :src="list.banner3" />
     <img :src="list.banner4" />
     <img :src="list.banner5" />
     <img :src="list.banner6" />
     <img :src="list.banner7" />
     <img :src="list.banner8" />
     <img :src="list.banner9" />
     <img :src="list.banner10" />
     <img :src="list.banner11" />
     <img :src="list.banner12" />
     <img :src="list.banner13" />
     <img :src="list.banner14" />
     <img :src="list.banner15" />
     <img :src="list.banner16" />
     <img :src="list.banner17" />
     <img :src="list.banner18" />
     <img :src="list.banner19" />
     <img :src="list.banner20" />
     <img :src="list.banner21" />
     <img :src="list.banner22" />
     <img :src="list.banner23" />
     <img :src="list.banner24" />
     <img :src="list.banner25" />
     <img :src="list.banner26" />
     <img :src="list.banner27" />
     <img :src="list.banner28" />
     <img :src="list.banner29" />
     <img :src="list.banner30" />
     <img :src="list.banner31" />
    </div>
    <span id="aa" style="width:1px;height:1px;"> {{ list.aa }}</span>
    <!--底部悬浮-->
    <div id="footerBar" class="footerBar">
     <table><tbody><tr>  
       <td style="color: red;font-size: 18px"><a :href="list.bb"> {{ list.cc }}</a></td>
       <!--<td style="color: red;font-size: 18px"><a href="#">在线客服</a></td>-->
       <td style="color: red;font-size: 18px" class="green"><a class="foot-btn" onclick="copyArt()">{{ list.dd }}</a></td>
      </tr></tbody></table>
    </div>
    <!--底部悬浮-->
  </div>


</div> 
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      tuiguang: null,
    }
  },
  mounted() {
    this.add();
  },
  methods: {
    async add() {
      axios.get('https://api.bf839.com/ad4.json').then((response) => {
        if (response) {
          this.tuiguang = response.data.data.tuiguang;
          console.log('数据获取成功');
        }
        else (
          alert('数据获取失败')
        )
      }
      )
    }
  },

}
</script>

 